// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  AdjustmentsVerticalIcon,
  Bars3Icon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import logoWhite from "../images/logo-white.png";
import Header from "../components/Header/Header";
import axios from "axios";
import { Link, navigate } from "gatsby";
import { Footer } from "../components/Footer/Footer";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/rootReducer";
import LoaderIcon from "../images/loader.svg";
import { useSearchParams } from "react-router-dom";
import CheckIcon from "../images/icon-check-circle.svg";
import { useResizeDetector } from "react-resize-detector";
import { Pagination } from "../components/Pagination/Pagination";
import { SearchOrder } from "../components/SearchOrder/SearchOrder";
import { Filters } from "../components/Filters/Filters";
import { Tags } from "../components/Filters/Tags";
import { ProductCard } from "../components/ProductCard/ProductCard";
import { Loader } from "../components/ProductCard/Loader";

const Search = ({ location }: any) => {
  const [sidebar, setSidebar] = useState(true);
  const [grid, setGrid] = useState(false);
  const [items, setItems] = useState([]);

  const [courses, setCourses] = useState<any>([]);
  const [catList, setCatsList] = useState<any>([]);
  const [skillList, setSkillList] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [skills, setSkills] = useState<any>([]);
  const [types, setTypes] = useState<any>([]);

  const [coursesCheck, setCoursesCheck] = useState<any>([]);
  const [skillsCheck, setSkillsCheck] = useState<any>([]);
  const [categoriesCheck, setCategoriesCheck] = useState<any>([]);
  const [sponsorsCheck, setSponsorsCheck] = useState<any>([]);

  const [queryList, setQueryList] = useState("");
  const [queryCat, setQueryCat] = useState("");
  const [querySpon, setQuerySpon] = useState("");
  const [querySkill, setQuerySkill] = useState("");
  const [numberResults, setNumberResults] = useState(0);

  const [searchTerm, setSearchTerm] = useState<any>("");
  const [searchCategory, setSearchCategory] = useState<any>("");

  const [openFilter, setOpenFilter] = useState(true);
  const [pagination, setPagination] = useState(false);
  const [searchingKey, setSearching] = useState(false);

  const [page, setPage] = useState(1);
  const [pageUsed, setPageUsed] = useState(1);
  const [pages, setPages] = useState<any>([]);

  const [checkedValue, setCheckedValue] = useState(false);
  const [checkedValueCategory, setCheckedValueCategory] = useState(false);
  const [checkedValueSponsor, setCheckedValueSponsor] = useState(false);

  const [firstLoad, setFirstLoad] = useState(true);

  const [sortVar, setSortvar] = useState("");
  const [sortText, setSortText] = useState("Sort By");

  const { width, height, ref } = useResizeDetector();

  useEffect(() => {
    if (width !== undefined) {
      if (width <= 460) setOpenFilter(false);
    }
  }, [width]);

  const config = {
    course: {
      value: checkedValue,
      check: coursesCheck,
      setValue: setCheckedValue,
      setCheck: setCoursesCheck,
      setQuery: setQueryList,
      filterText: `type.name`,
    },
    category: {
      value: checkedValueCategory,
      check: categoriesCheck,
      setValue: setCheckedValueCategory,
      setCheck: setCategoriesCheck,
      setQuery: setQueryCat,
      filterText: `categories.slug`,
    },
    sponsor: {
      value: checkedValueSponsor,
      check: sponsorsCheck,
      setValue: setCheckedValueSponsor,
      setCheck: setSponsorsCheck,
      setQuery: setQuerySpon,
      filterText: `sponsor.title`,
    },
    skill: {
      setQuery: setQuerySkill,
      filterText: `skills.name`,
    },
  };

  const handleFilters = (term: string, filter: string) => {
    let itemsHere = [...config[filter]["check"]];
    let itemsNew = [];

    if (filter === "course") {
      if (term === "Course") {
        itemsHere.map((item: any) => {
          if (item.slug === term) item.checked = !item.checked;
          itemsNew.push(item);
        });
      }
      if (term === "Certificate") {
        itemsHere.map((item: any) => {
          if (item.slug !== "Course") item.checked = !item.checked;
          itemsNew.push(item);
        });
      }
    } else {
      itemsHere.map((item) => {
        if (item.slug === term) item.checked = !item.checked;
        itemsNew.push(item);
      });
    }

    config[filter]["setCheck"](itemsNew);
    const truest = itemsNew.filter((item: any) => item.checked === true);
    let newQuery = "";
    if (truest.length) {
      truest.map(
        (item: any) =>
          (newQuery += `&facetFilters[${config[filter]["filterText"]}]=${item.slug}`)
      );
      config[filter]["setQuery"](newQuery);
    } else {
      config[filter]["setQuery"]("");
    }
  };

  const handleSearch = (term: string, search: string) => {
    let itemsHere = [...config[search]["check"]];
    let itemsNew = [];
    if (term === "all") {
      itemsHere.map((item) => {
        item.checked = true;
        itemsNew.push(item);
      });

      config[search]["setValue"](true);
      config[search]["setCheck"](itemsNew);

      let newQuery = "";
      const truest = itemsNew.filter((item: any) => item.checked === true);
      if (truest.length) {
        truest.map(
          (item: any) =>
            (newQuery += `&facetFilters[${config[search]["filterText"]}]=${item.slug}`)
        );
        config[search]["setQuery"](newQuery);
      } else {
        config[search]["setQuery"]("");
      }
    } else {
      config[search]["setValue"](false);
      handleFilters(term, search);
    }
  };

  const locationFunctions = (
    typeElements: any,
    catElements: any,
    sponsorElements: any
  ) => {
    let search = location.search;
    let params = new URLSearchParams(search);

    let type = params.get("type");
    let query = params.get("q");
    let skilled = params.get("skill");
    let category = params.get("category");

    if (type !== null) searchIndividualPro("course", type, typeElements);
    if (category !== null)
      searchIndividualPro("category", category, catElements);
    if (skilled !== null) searchIndividualPro("skill", skilled);

    const valueOfSearch = location.search
      ? location.search.replace("?q=", "")
      : null;
    if (type === null && category === null && valueOfSearch === null) {
      getForCourse();
      setSearchTerm("");
    }

    if (query !== null) {
      // sessionStorage.setItem('wf_search', query)
      setSearchTerm(query);
      getForCourse();
    }
  };

  const searchIndividualPro = async (
    filter: string,
    term: string,
    typeElements: any
  ) => {
    setCheckedValue(false);
    setCheckedValueCategory(false);
    setCheckedValueSponsor(false);

    setQueryList("");
    setQueryCat("");
    setQuerySpon("");
    setQuerySkill("");

    let newQuery = "";
    if (typeElements) {
      if (term === "Certificate") {
        typeElements.map((item: any) => {
          if (item.slug !== "Course") item.checked = !item.checked;
        });
        const truest = typeElements.filter(
          (item: any) => item.checked === true
        );
        truest.map(
          (item: any) =>
            (newQuery += `&facetFilters[${config[filter]["filterText"]}]=${item.name}`)
        );
        config[filter]["setCheck"](typeElements);
        config[filter]["setQuery"](newQuery);
      } else {
        typeElements.map((item) => {
          if (item.slug === term) item.checked = !item.checked;
        });
        config[filter]["setCheck"](typeElements);
        config[filter]["setQuery"](
          `&facetFilters[${config[filter]["filterText"]}]=${term}`
        );
      }
    } else {
      config[filter]["setQuery"](
        `&facetFilters[${config[filter]["filterText"]}]=${term}`
      );
    }
  };

  useEffect(() => {
    gettingTypeAndCategory();
  }, [location]);

  const gettingTypeAndCategory = async () => {
    let typeElements: any = [];
    let catElements: any = [];
    let sponsorElements: any = [];

    const {
      data: { data: types },
    } = await axios.get(`${process.env.API_URL}/api/type`);
    types.map((item: any) =>
      typeElements.push({
        name: item.name,
        slug: item.name,
        checked: item.name == false,
      })
    );

    const {
      data: { data: categories },
    } = await axios.get(`${process.env.API_URL}/api/category`);
    categories.map((item: any) =>
      catElements.push({
        name: item.name,
        slug: item.slug,
        checked: item.name == false,
      })
    );

    const {
      data: { data: sponsors },
    } = await axios.get(`${process.env.API_URL}/api/sponsor`);
    sponsors.map((item: any) =>
      sponsorElements.push({
        name: item.title,
        slug: item.title,
        checked: item.name == false,
      })
    );

    setCoursesCheck(typeElements);
    setCategoriesCheck(catElements);
    setSponsorsCheck(sponsorElements);
    locationFunctions(typeElements, catElements, sponsorElements);
  };

  const showGrid = () => setGrid(true);

  const hideGrid = () => setGrid(false);

  const handleOpenFilter = () => setOpenFilter(!openFilter);

  const handleTerm = (newTerm: string) => {
    setSearchTerm(newTerm);
    getForCourse();
  };

  const userName =
    typeof window !== "undefined" && localStorage.getItem("name");
  const [signed, setSigned] = useState(false);

  const loginElement: any = useSelector((state: RootState) => state.loginSlice);

  useEffect(() => {
    if (loginElement.items.status) {
      setSigned(true);
    }
  }, [loginElement]);

  const getForCourse = async () => {
    console.log("using this function getForCourse");
    setSearching(true);
    await axios
      .get(
        `${process.env.API_URL}/api/info/algolia/search?limit=12&page=${
          pageUsed - 1
        }${
          searchTerm !== "" ? "&query=" + searchTerm : ""
        }${queryList}${querySpon}${queryCat}${querySkill}${sortVar}`
      )
      .then((response) => {
        setNumberResults(response.data.data.nbHits);
        if (parseInt(response.data.data.nbPages) > 1) {
          let pagesToUse = [];
          setPage(response.data.data.nbPages);
          setPagination(true);
          for (let i = 0; i < parseInt(response.data.data.nbPages); i++) {
            pagesToUse.push({ number: i + 1, value: i });
          }
          setPages(pagesToUse);
        } else {
          setPage(1);
          setPagination(false);
        }
        setItems(response.data.data.hits);
      })
      .catch(function (error) {
        console.log(error);
      });
    setSearching(false);
    setFirstLoad(false);
  };

  const clearFilters = () => {
    let courses = [...coursesCheck];
    let categories = [...categoriesCheck];
    let sponsors = [...sponsorsCheck];
    courses.map((item) => {
      item.checked = false;
    });
    categories.map((item) => {
      item.checked = false;
    });
    sponsors.map((item) => {
      item.checked = false;
    });
    setCoursesCheck(courses);
    setCategoriesCheck(categories);
    setSponsorsCheck(sponsors);

    setCheckedValue(false);
    setCheckedValueCategory(false);
    setCheckedValueSponsor(false);

    setQueryList("");
    setQueryCat("");
    setQuerySpon("");
    setQuerySkill("");
  };

  useEffect(() => {
    // console.log("listener queryList =>", queryList);
    getForCourse();
  }, [
    queryList,
    queryCat,
    querySpon,
    querySkill,
    pageUsed,
    sortVar,
    searchTerm,
  ]);

  return (
    <Layout signed={signed} handleTerm={handleTerm}>
      <div className="bg-slate-50" ref={ref}>
        {/* <Header isSignIn={signed} handleTerm={handleTerm} /> */}

        <section className="container px-[15px] mx-auto mt-[30px] mb-[30px]">
          <div className="flex flex-col lg:flex-row lg:items-center justify-between">
            <div className="flex flex-col" style={{ width: "65%" }}>
              <h2 className="text-[30px] leading-[30px] lg:leading-10 lg:text-[40px] ff-cg--semibold">
                {numberResults > 0
                  ? `${numberResults} results ${
                      searchTerm !== "" && searchTerm !== undefined
                        ? `for "${searchTerm}"`
                        : ""
                    }`
                  : ""}
              </h2>
              <div className="flex flex-wrap gap-1 lg:gap-2 items-center  mt-4 lg:mb-0">
                <Tags
                  term={"course"}
                  items={config["course"]["check"]}
                  handleSearch={handleSearch}
                />
                <Tags
                  term={"category"}
                  items={config["category"]["check"]}
                  handleSearch={handleSearch}
                />
                <Tags
                  term={"sponsor"}
                  items={config["sponsor"]["check"]}
                  handleSearch={handleSearch}
                />
                {coursesCheck.some((item: any) => item.checked) ||
                categoriesCheck.some((item: any) => item.checked) ||
                sponsorsCheck.some((item: any) => item.checked) ? (
                  <span
                    className="underline ff-cg--semibold cursor-pointer text-sm text-[#222222]"
                    onClick={() => clearFilters()}
                  >
                    Clear All
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between lg:justify-end mt-5">
                {!openFilter ? (
                  <button
                    className="flex sm:hiden items-center justify-between border border-[#222222] py-[14px] px-[16px] rounded-2xl mr-3"
                    onClick={() => setOpenFilter(!openFilter)}
                  >
                    <AdjustmentsVerticalIcon className="h-6 w-6" />
                    <span className="ff-cg--semibold ml-[20px]">
                      Show Filters
                    </span>
                  </button>
                ) : (
                  ""
                )}
                <div className="flex items-center">
                  <SearchOrder
                    functions={{ setSortText, setSortvar }}
                    values={{ sortText, sortVar }}
                  />
                  <button
                    className={`hidden md:flex items-center justify-between border-2 border-[#222222] py-2 px-2 rounded-xl ml-4 ${
                      !grid ? "text-white bg-[#222222] " : ""
                    }`}
                    onClick={() => hideGrid()}
                  >
                    <Bars3Icon className="h-6 w-6" />
                  </button>
                  <button
                    className={`hidden md:flex items-center justify-between border-2 border-[#222222] py-2 px-2 rounded-xl ml-4 ${
                      grid ? "text-white bg-[#222222] " : ""
                    }`}
                    onClick={() => showGrid()}
                  >
                    <Squares2X2Icon className="h-6 w-6" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container px-[15px] md:px-[40px] lg:px-[8px] mx-auto mt-[30px] mb-[40px]">
          <div className="lg:grid gap-4 lg:gap-10 lg:grid-cols-12">
            {openFilter ? (
              <Filters
                functions={{ setOpenFilter, handleFilters, handleSearch }}
                values={{ openFilter }}
                config={config}
              />
            ) : (
              ""
            )}
            <div
              className={`${openFilter ? "lg:col-span-9" : "lg:col-span-12"}`}
            >
              {!searchingKey ? (
                <>
                  {items.length ? (
                    <div
                      className={
                        grid
                          ? "grid gap-4 md:gap-5 lg:gap-8 md:grid-cols-12"
                          : "md:grid gap-4 lg:gap-10 md:grid-cols-12"
                      }
                    >
                      {items.map((item: any, index: number) => (
                        <ProductCard
                          item={item}
                          index={index}
                          grid={grid}
                          key={index}
                        />
                      ))}
                    </div>
                  ) : (
                    <Loader firstLoad={firstLoad} />
                  )}
                </>
              ) : (
                <div className="w-full h-full flex justify-center pt-20">
                  <div className="text-center">
                    <img
                      src={LoaderIcon}
                      className={`w-[120px] h-[120px] mmb-4 inline-block`}
                    />
                    <p className="font-bold text-4xl mb-2 ff-cg--semibold">
                      Loading Results
                    </p>
                    <p className="text-xl">
                      Please wait a moment while we set things up for you!
                    </p>
                  </div>
                </div>
              )}
              {pagination && (
                <Pagination
                  functions={{ setPage, setPageUsed, setPages }}
                  values={{ page, pageUsed, pages }}
                />
              )}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Layout>
  );
};

export default Search;
export { Head } from "../components/Layout/Head";
