import React from "react";
import { Link } from "gatsby";
import { ClockIcon } from "@heroicons/react/24/outline";

export const CardLarge = (props: any) => {
  return (
    <div
      className="md:col-span-12 lg:col-span-12 cursor-pointer mb-6 md:mb-0"
      key={props.index}
    >
      <Link
        to={`/course/${props.item.slug}`}
        state={{ id: props.item.title, course: props.item }}
      >
        <div className="rounded-3xl bg-white flex flex-col md:flex-row shadow-lg relative items-center py-0">
          <div className="relative w-full md:min-w-[200px] md:w-[200px]">
            <div className="before:bg-black before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:rounded-3xl before:opacity-50"></div>
            <img
              className="w-full lg:w-[200px] object-cover h-[150px] rounded-3xl bg-slate-300"
              src={props.item.imgUrl}
              alt=""
            />
            <div className="absolute w-full h-full z-100 flex items-center justify-center top-0 flex-col">
              {/* <div className="text"> */}
              <p className="text-white">In partnership with:</p>
              <img
                className="w-auto object-cover h-8 mt-2"
                src={props.item.sponsor.imgUrl}
                alt=""
              />
              {/* </div> */}
            </div>
          </div>
          <div className="rounded-3xl md:rounded-none h-[270px] md:h-auto bg-white md:bg-transparent p-[20px] md:pl-8 md:p-5 flex flex-col md:flex-row items-center justify-between w-full mt-[-30px] md:mt-0 relative">
            <div className="flex flex-col w-full ">
              <div className="order-2 md:order-1">
                <h4 className="text-[16px] lg:text-[20px] ff-cg--semibold leading-none mb-[10px]">
                  {props.item.title}
                </h4>
                <p className="mb-[10px] leading-none lg:leading-normal max-h-[30px] lg:max-h-[50px] overflow-hidden">
                  {props.item.description > 130
                    ? props.item.description.substring(0, 130) + "..."
                    : props.item.description}
                </p>
              </div>
              <div className="order-1 md:order-2 flex flex-wrap gap-1 lg:gap-2 items-center  mb-[16px] lg:mb-0">
                {props.item.categories.map((category: any, index: number) => {
                  return (
                    <span
                      className="flex items-center border border-red-500 rounded-full pl-[10px] pr-[10px]"
                      key={index}
                    >
                      <span className="ff-cg--semibold text-[12px]">
                        {category.name}
                      </span>
                    </span>
                  );
                })}
                <span className="flex hidden md:flex items-center border border-red-500 rounded-full pl-[3px] pr-[10px] mr-4">
                  <ClockIcon className="h-4 w-4 mr-[6px]" />
                  <span className="ff-cg--semibold text-[12px]">
                    {props.item.duration}
                  </span>
                </span>
              </div>
            </div>
            <button className="lg:w-fit flex w-full flex-col mt-4 md:mt-0 items-center justify-between md:border md:solid md:border-black p-0 md:py-[5px] md:px-[16px] md:rounded-2xl md:ml-[20px]">
              <div className="flex flex-row md:flex-col justify-between w-full md:w-auto items-center">
                <span className="ff-cg--semibold text-[20px] md:ff-cg--bold md:text-[24px] md:leading-none">
                  {Number(props.item.price).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    currencyDisplay: "symbol",
                  })}
                </span>
                <span className="ff-cg--semibold text-[12px] leading-none hidden md:block">
                  Price
                </span>
                <span className="flex block md:hidden items-center border border-red-500 rounded-full pl-[3px] pr-[10px]">
                  <ClockIcon className="h-4 w-4 mr-[6px]" />
                  <span className="ff-cg--semibold text-[12px]">
                    {props.item.duration}
                  </span>
                </span>
              </div>
            </button>
          </div>
        </div>
      </Link>
    </div>
  );
};
