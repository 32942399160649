import React from "react";
import LoaderIcon from '../../images/loader.svg'

export const Loader = (props) => {
  return (
    <>
      {
        props.firstLoad ?
          <div className="w-full h-full flex justify-center pt-20">
            <div className="text-center">
              <img src={LoaderIcon} className={`w-[120px] h-[120px] mmb-4 inline-block`} />
              <p className="font-bold text-4xl mb-2 ff-cg--semibold">Loading Results</p>
              <p className="text-xl">Please wait a moment while we set things up for you!</p>
            </div>
          </div> :
          <div className="text-center">
            <p className="text-[30px] leading-[30px] lg:leading-10 mb-3 lg:text-[40px] ff-cg--semibold">
              No results were found for you search
            </p>
          </div>
      }
    </>
  )
}