import React, { useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'

export const SearchOrder = (props) => {
  const [filterOpen, setFilterOpen] = useState(false);

  const sortOptions = (sortBy: string, sortTextNew: string) => {
    let sortTextString = "&index=" + sortBy;
    props.functions.setSortvar(sortTextString);
    props.functions.setSortText(sortTextNew)
    setFilterOpen(!filterOpen);
  }

  return (
    <div className="relative z-10">
      <button className="items-center flex justify-between bg-white shadow-lg py-[14px] px-[16px] rounded-2xl" onClick={() => setFilterOpen(!filterOpen)}>
        <span className="mr-[20px]">{props.values.sortText}</span>
        <ChevronDownIcon className="h-6 w-6" />
      </button>
      {
        (filterOpen) ?
          <div className="absolute bg-white rounded-xl shadow-lg border-2 min-w-[250px] border-black right-0 lg:left-[-60px] top-[56px]">
            <p className="px-3 py-2 ff-cg--semibold border-b-2 border-black cursor-pointer" onClick={() => sortOptions("index_courses_title_asc", "Product Name A-Z")}>Product Name A-Z</p>
            <p className="px-3 py-2 ff-cg--semibold border-b-2 border-black cursor-pointer" onClick={() => sortOptions("index_courses_title_desc", "Product Name Z-A")}>Product Name Z-A</p>
            <p className="px-3 py-2 ff-cg--semibold border-b-2 border-black cursor-pointer" onClick={() => sortOptions("index_courses_price_asc", "Price Low to High")}>Price Low to High</p>
            <p className="px-3 py-2 ff-cg--semibold cursor-pointer" onClick={() => sortOptions("index_courses_price_desc", "Price High to Low")}>Price High to Low</p>
          </div> : ""
      }
    </div>
  )
}