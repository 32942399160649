import React from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'

export const Tags = (props) => {

  return (
    <>
      {
        props.items.length > 0 && props.items.map((item: any, index: number) => {
          const config = {
            course: item.slug,
            category: item.slug,
            sponsor: item.name
          }
          if (item.checked) {
            if (props.term === 'course') {
              if (item.slug === "Course" || item.slug === "Certificate") {
                return (
                  <span className="flex items-center border border-black rounded-full py-1 pl-[10px] pr-[10px]"
                    key={index}
                    onClick={(e) => props.handleSearch(config[props.term], props.term)}
                  >
                    <span className="ff-cg--semibold text-[12px]">{item.name}</span>
                    <XMarkIcon className="h-4 w-4 ml-2 cursor-pointer" />
                  </span>
                )
              }
            } else {
              return (
                <span className="flex items-center border border-black rounded-full py-1 pl-[10px] pr-[10px]"
                  key={index}
                  onClick={(e) => props.handleSearch(config[props.term], props.term)}
                >
                  <span className="ff-cg--semibold text-[12px]">{item.name}</span>
                  <XMarkIcon className="h-4 w-4 ml-2 cursor-pointer" />
                </span>
              )
            }
          }
        })
      }
    </>
  )
}