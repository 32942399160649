import React from 'react'

export const Checks = (props) => {
  return (
    <div className="flex pt-[14px] pb-[24px]">
      <div className="course-form">
        <div className="mb-[13px] flex items-center">
          <input className="appearance-none rounded-full h-4 w-4 border-2 border-white checked:bg-[url('../images/icon-check-circle.svg')] checked:border-amber-400 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            type="checkbox"
            checked={props.checkedValue}
            name={`flexRadioDefault${props.terms}00`}
            id={`flexRadioDefault${props.terms}00`}
            onChange={(e) => props.handleSearch('all', props.term)}
          />
          <label className="inline-block text-white ff-cg--semibold text-[13px]" htmlFor={`flexRadioDefault${props.terms}00`}>
            All
          </label>
        </div>
        {
          props.items.length > 0 && props.items.map((item: any, index: number) => {
            if (props.term === 'course') {
              if (item.slug === "Course" || item.slug === "Certificate") {
                return (
                  <div className="mb-[13px] flex items-center" key={`${props.term}-${index}`}>
                    <input
                      className="appearance-none rounded-full h-4 w-4 border-2 border-white checked:bg-[url('../images/icon-check-circle.svg')] checked:border-amber-400 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      name={`flexRadioDefault${props.term}-${index}`}
                      id={`flexRadioDefault${props.term}-${index}`}
                      checked={item.checked}
                      onChange={(e) => props.handleSearch(item.slug, props.term)}
                    />
                    <label className="inline-block text-white ff-cg--semibold text-[13px]"
                      htmlFor={`flexRadioDefault${props.term}-${index}`}>
                      {item.name}
                    </label>
                  </div>
                )
              }
            } else {
              return (
                <div className="mb-[13px] flex items-center" key={`${props.term}-${index}`}>
                  <input className="appearance-none rounded-full h-4 w-4 border-2 border-white checked:bg-[url('../images/icon-check-circle.svg')] checked:border-amber-400 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="checkbox"
                    name={`flexRadioDefault${props.term}-${index}`}
                    id={`flexRadioDefault${props.term}-${index}`}
                    checked={item.checked}
                    onChange={(e) => props.handleSearch(item.slug, props.term)}
                  />
                  <label className="inline-block text-white ff-cg--semibold text-[13px]"
                    htmlFor={`flexRadioDefault${props.term}-${index}`}>
                    {item.name}
                  </label>
                </div>
              )
            }
          })
        }
      </div>
    </div>
  )
}