import React from "react";
import { Link } from "gatsby";
import { ClockIcon } from "@heroicons/react/24/outline";

export const CardShort = (props: any) => {
  return (
    <div className="md:col-span-6 lg:col-span-4" key={props.index}>
      <Link
        to={`/course/${props.item.slug}`}
        state={{ id: props.item.title, course: props.item }}
      >
        <div className="relative">
          <div className="before:bg-black before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:rounded-3xl before:opacity-50"></div>
          <img
            className="w-full object-cover h-[160px] rounded-3xl bg-slate-300"
            src={props.item.imgUrl}
            alt=""
          />
          <div className="absolute w-full h-full z-100 flex items-center justify-center top-0 flex-col">
            {/* <div className="text"> */}
            <p className="text-white">In partnership with:</p>
            <img
              className="w-auto object-cover h-8 mt-2"
              src={props.item.sponsor.imgUrl}
              alt=""
            />
            {/* </div> */}
          </div>
        </div>
        <div className="rounded-3xl bg-white p-[20px] flex flex-col justify-between md:h-[345px] 2xl:h-[320px] mt-[-30px] shadow-lg relative box-border">
          <div className="h-[calc(100%-1.5rem)] overflow-hidden">
            <div className="flex flex-wrap items-center gap-2 mb-[16px]">
              {props.item.categories.map((category: any, index: number) => {
                return (
                  <span
                    className="flex items-center border border-red-500 rounded-2xl pl-[10px] pr-[10px]"
                    key={index}
                  >
                    <span className="ff-cg--semibold text-[12px]">
                      {category.name}
                    </span>
                  </span>
                );
              })}
            </div>
            <h4 className="text-[16px] lg:text-[20px] ff-cg--semibold leading-none mb-[10px]">
              {props.item.title}
            </h4>
            <p className="md:max-h-[70%] lg:max-h-none overflow-hidden text-base">
              {props.item.description}
            </p>
          </div>
          <div className="flex items-center justify-between mt-2 md:mt-0">
            <div className="flex items-center">
              <p className="ff-cg--semibold text-base xl:text-[20px]">
                {Number(props.item.price).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  currencyDisplay: "symbol",
                })}
              </p>
            </div>
            <span className="flex items-center border border-red-500 rounded-full pl-[3px] pr-[10px]">
              <ClockIcon className="h-4 w-4 mr-[6px]" />
              <span className="ff-cg--semibold text-[12px]">
                {props.item.duration}
              </span>
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};
