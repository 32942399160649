import React from 'react'
import {
  BookmarkSquareIcon,
  BookOpenIcon,
  ArrowLeftCircleIcon,
  XMarkIcon,
  WrenchScrewdriverIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/outline'
import { Checks } from './Checks'

export const Filters = (props) => {
  return (
    <div className="lg:col-span-3">
      <div className={"fixed overflow-auto lg:overflow-visible top-0 left-0 right-0 z-[100] lg:z-0 bg-[#DA1A32] h-full p-[15px] lg:rounded-2xl mb-[40px] lg:block lg:relative " + (props.values.openFilter ? 'block' : 'hidden')}>
        <button className="bg-white shadow-lg py-2 px-2 rounded-lg absolute right-[-18px] top-[28px] hidden lg:inline-block" onClick={() => props.functions.setOpenFilter(!props.values.openFilter)}>
          <ArrowLeftCircleIcon className="h-5 w-5" />
        </button>
        <p className="flex lg:block p-[15px] lg:p-0 text-white text-[30px] ff-cg--semibold lg:py-[10px]">
          Filters
          <button onClick={() => props.functions.setOpenFilter(!props.values.openFilter)} className="m-[15px] absolute top-[20px] right-0 lg:hidden">
            <XMarkIcon className="w-9 h-9" />
          </button>
        </p>
        <div className="block">
          <div className="flex items-center border-b solid pb-[10px] border-red-300">
            <div className="bg-red-300 h-6 w-6 rounded-full flex items-center justify-center">
              <BookOpenIcon className="h-5 w-5 text-white" />
            </div>
            <p className="ff-cg--semibold text-white ml-[6px]">Ways to Learn</p>
          </div>
          <Checks
            checkedValue={props.config['course']['value']}
            handleSearch={props.functions.handleSearch}
            items={props.config['course']['check']}
            term={'course'}
          />
          <div className="flex items-center border-b solid pb-[10px] border-red-300">
            <div className="bg-red-300 h-6 w-6 rounded-full flex items-center justify-center">
              <BookmarkSquareIcon className="h-5 w-5 text-white" />
            </div>
            <p className="ff-cg--semibold text-white ml-[6px]">Categories</p>
          </div>
          <Checks
            checkedValue={props.config['category']['value']}
            handleSearch={props.functions.handleSearch}
            items={props.config['category']['check']}
            term={'category'}
          />
          <div className="flex items-center border-b solid pb-[10px] border-red-300">
            <div className="bg-red-300 h-6 w-6 rounded-full flex items-center justify-center">
              <WrenchScrewdriverIcon className="h-5 w-5 text-white" />
            </div>
            <p className="ff-cg--semibold text-white ml-[6px]">Vendors</p>
          </div>
          <Checks
            checkedValue={props.config['sponsor']['value']}
            handleSearch={props.functions.handleSearch}
            items={props.config['sponsor']['check']}
            term={'sponsor'}
          />
          <div className="block lg:hidden w-[300px]">
            <span className="w-full cursor-pointer lg:w-fit flex items-center justify-between bg-[#fdbf38] py-[14px] px-[16px] rounded-2xl mt-[15px] mr-0 ml-0"
              onClick={() => props.functions.setOpenFilter(!props.values.openFilter)}>
              <span className="ff-cg--semibold mr-[20px]">Apply filters</span>
              <ArrowRightCircleIcon className="h-6 w-6" />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}