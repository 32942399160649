import React from 'react'
import {
  ChevronRightIcon,
  ChevronLeftIcon,
} from '@heroicons/react/24/outline'

export const Pagination = (props) => {
  const handlePagination = (pageIndex: number) => {
    props.functions.setPageUsed(pageIndex + 1);
  }

  const handlePaginationArrows = (direction: string, page: number) => {
    let pageIndex = 0;
    pageIndex = page <= 1 ? 0 : page - 1
  
    if (direction === "return") {
      if (page > 1) {
        pageIndex = pageIndex - 1;
        props.functions.setPageUsed(pageIndex + 1);
      }
    } else {
      if (page < props.values.pages.length) {
        pageIndex = pageIndex + 1;
        props.functions.setPageUsed(pageIndex + 1);
      }
    }
  }

  return (
    <div className="grid gap-4 lg:gap-10 md:grid-cols-12 mt-10">
      <div className="md:col-span-12 lg:col-span-12">
        <div className="flex flex-wrap items-center justify-center">
          <span onClick={() => handlePaginationArrows("return", props.values.pageUsed)} className="cursor-pointer">
            <ChevronLeftIcon className="h-6 w-6" />
          </span>
          {
            (props.values.pages.length <= 5) ?
              <>
                {
                  props.values.pages.map((item: any, index: number) => {
                    return (
                      <button key={index} className={`mx-2 flex items-center justify-center ff-cg--semibold border border-[#222222] w-10 h-10 rounded-xl ${(item.number === props.values.pageUsed ? "text-white bg-[#DA1A32]" : "text-white bg-[#222222]")}`} onClick={() => handlePagination(item.value)}>
                        <span>{item.number}</span>
                      </button>
                    )
                  })
                }
              </> :
              <>
                {
                  props.values.pages.slice(props.values.pageUsed - 1, props.values.pageUsed + 4).map((item: any, index: number) => {
                    return (
                      <button key={index} className={`mx-2 flex items-center justify-center ff-cg--semibold border border-[#222222] w-10 h-10 rounded-xl ${(item.number === props.values.pageUsed ? "text-white bg-[#DA1A32]" : "text-white bg-[#222222]")}`} onClick={() => handlePagination(item.value)}>
                        <span>{item.number}</span>
                      </button>
                    )
                  })
                }
              </>
          }
          <span onClick={() => handlePaginationArrows("advance", props.values.pageUsed)} className="cursor-pointer">
            <ChevronRightIcon className="h-6 w-6" />
          </span>
        </div>
      </div>
    </div>
  )
}